<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 200 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
        >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left> work </v-icon>
            Job Title
          </div>
          <v-icon> expand_more </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-text class="pt-4" :class="{ 'black--text': isThemeLight }">
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon :class="{ 'black--text': isThemeLight }" class="mr-1" left> volume_down </v-icon>

              Job Title
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :color="isThemeLight ? 'black' : null" v-bind="attrs" v-on="on"> info </v-icon>
              </template>

              <span> Identify the influencer by their work profile/job title. </span>
            </v-tooltip>
          </div>

          <v-text-field
            v-model="form.value"
            dense
            outlined
            hide-details
            placeholder="Enter your query"
            @keyup.enter="submitForm"
            class="mt-3"
          ></v-text-field>

          <v-checkbox
            v-model="form.shouldExclude"
            label="Exclude Job Title"
            @change="submitForm"
          ></v-checkbox>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// Import helper validation functions
import { required } from "vuelidate/lib/validators"

// Key for this filter
const filterName = "jobTitle"

// Default form object generator
const originalForm = () => ({
  value: "",
  shouldExclude: false
})

// Export the SFC
export default {
  // Name of the component
  name: "FilterJobTitle",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    form: originalForm(),

    shouldShowModal: false
  }),

  // Define local readonly properties
  computed: {
    /**
     * Show the filter as a dialog for mobile dialogs
     *
     * @returns {void}
     */
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    /**
     * Whether or not this filter is in use
     * Used to bold the filter name text
     *
     * @returns {void}
     */
    isFilterApplied() {
      return Boolean(this.$store.getters["influencerDiscovery/findFiltersByType"](filterName).length)
    }
  },

  // Define validations for the form
  validations: {
    form: {
      value: {
        required
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     *
     * @returns {String}
     */
    computeFilterText() {
      return `Job Title: ${this.form.shouldExclude ? "Excluding - " : ""} ${this.form.value}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     * @returns {void}
     */
    async submitForm() {
      await this.$v.$touch()

      if (this.$v.$anyError) return

      this.$store.dispatch("influencerDiscovery/replaceFilter", {
        type: filterName,
        data: {
          color: "pink lighten-5",
          icon: "work",
          iconColor: "pink",
          text: this.computeFilterText(),
          inputs: {
            ...this.form
          }
        }
      })

      this.shouldShowModal = false
    }
  },

  /**
   * As soon as the filter has been rendered
   *
   * @returns {void}
   */
  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form = originalForm()
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form.value = e.detail.item.data.inputs.value
    })
  }
}
</script>
